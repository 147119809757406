@import "../../../screensize.scss";
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .search-gridloader{
        display: flex;
        justify-content:center;
        margin-top: 10%;

    }
    .search-container{
        width: auto;
        margin: 20px 20px;
        columns: 2;
        @include md{
            margin: 20px 20px;
            columns: 3;
        }
        @include lg{
            margin: 20px 200px;
            columns: 4;
        }
        .search-Imagecard-container{
            width: auto;
            margin-bottom: 10px;
            break-inside: avoid;
            img{
                width: 100%;
                border-radius: 10px;
            }
        } 
    }    

    .search-pagechange-container{
        text-align: center;
    }
}