@import './../../../screensize.scss';
*{
    margin: 0px;
    padding: 0;
    box-sizing:border-box;
    font-family: cursive;
    .Header-div{
        border-bottom: 2px solid rgb(216, 216, 216);
        background-color: rgb(0, 0, 0);
        color: white;

        .Header-top{
            display: flex;
            justify-content:left;
            align-items:center;
            padding: 0 10px;

            @include md{
                padding: 0 100px;
                align-items: center;
                justify-content: space-between;
            }
            @include lg{
                padding: 0 200px;
                align-items: center;
                justify-content: space-between;
            }

            .header-top-left{
                width: fit-content;
                align-items:start;
                cursor: pointer;

                @include lg{
                    align-items: center;
                }

                img{
                    width: 40px;
                    margin: 10px;
                    padding-top:10px;

                    @include md{
                        width: 50px;
                    }
                    @include lg{
                        width: 60px;
                    }
                }
            }

            .header-top-middle{
                width: 100%;
                padding: 10px 10px;
                background: none;

                @include md{
                    padding: 10px 50px;
                }

                @include lg{
                    padding: 10px 100px;
                }
                
                
                input{
                    width: 100%;
                    padding:10px 10px;
                    border: none;
                    background: none;
                    border-radius: 20px;
                    background-color: rgb(255, 255, 255);

                    @include md{
                        padding:13px 20px;
                    }

                    @include lg{
                        width: 100%;
                        padding:15px 30px;
                    }
                }

                
            }

            .header-top-right{
                width: fit-content;
                padding: 10px;
                display: none;
                @include lg{
                    display: flex;
                }
                span{
                    margin: 10px;
                    font-size: large;
                    cursor: pointer;
                }
            }
        }
    }
    .header-bottom{
        display: none;
        justify-content: space-between;
        padding: 0 20px;
        margin-bottom: 10px;

        @include md{
            display: flex;
        }
        @include lg{
            display: flex;
        }
        // .Header-bottom-left{
        //     padding: 20px 10px;
        //     &:hover{
        //         background-color:white;
        //         border-radius: 20px;
        //         color:black
        //     }
        // }
        .Header-bottom-middle {
            width: 100%;
            text-align: center;
            padding: 20px;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: hidden; 
          
            p {
              width: auto; 
              margin: 0 10px;
              white-space: nowrap;
              cursor: pointer;
          
              &:hover {
                border-bottom: 2px solid white;
              }
            }
          }
          
        // .Header-bottom-right{
        //     padding: 20px 10px;
        //     cursor: pointer;
        //     &:hover{
        //         background-color:white;
        //         border-radius: 20px;
        //         color:black;
        //     }
            
        // }
    }
}