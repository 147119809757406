* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: cursive;
}

* .Header-div {
  color: #fff;
  background-color: #000;
  border-bottom: 2px solid #d8d8d8;
}

* .Header-div .Header-top {
  justify-content: left;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

@media only screen and (width >= 768px) {
  * .Header-div .Header-top {
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
  }
}

@media only screen and (width >= 1024px) {
  * .Header-div .Header-top {
    justify-content: space-between;
    align-items: center;
    padding: 0 200px;
  }
}

* .Header-div .Header-top .header-top-left {
  cursor: pointer;
  align-items: start;
  width: -moz-fit-content;
  width: fit-content;
}

@media only screen and (width >= 1024px) {
  * .Header-div .Header-top .header-top-left {
    align-items: center;
  }
}

* .Header-div .Header-top .header-top-left img {
  width: 40px;
  margin: 10px;
  padding-top: 10px;
}

@media only screen and (width >= 768px) {
  * .Header-div .Header-top .header-top-left img {
    width: 50px;
  }
}

@media only screen and (width >= 1024px) {
  * .Header-div .Header-top .header-top-left img {
    width: 60px;
  }
}

* .Header-div .Header-top .header-top-middle {
  background: none;
  width: 100%;
  padding: 10px;
}

@media only screen and (width >= 768px) {
  * .Header-div .Header-top .header-top-middle {
    padding: 10px 50px;
  }
}

@media only screen and (width >= 1024px) {
  * .Header-div .Header-top .header-top-middle {
    padding: 10px 100px;
  }
}

* .Header-div .Header-top .header-top-middle input {
  background: #fff;
  border: none;
  border-radius: 20px;
  width: 100%;
  padding: 10px;
}

@media only screen and (width >= 768px) {
  * .Header-div .Header-top .header-top-middle input {
    padding: 13px 20px;
  }
}

@media only screen and (width >= 1024px) {
  * .Header-div .Header-top .header-top-middle input {
    width: 100%;
    padding: 15px 30px;
  }
}

* .Header-div .Header-top .header-top-right {
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: none;
}

@media only screen and (width >= 1024px) {
  * .Header-div .Header-top .header-top-right {
    display: flex;
  }
}

* .Header-div .Header-top .header-top-right span {
  cursor: pointer;
  margin: 10px;
  font-size: large;
}

* .header-bottom {
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 20px;
  display: none;
}

@media only screen and (width >= 768px) {
  * .header-bottom {
    display: flex;
  }
}

@media only screen and (width >= 1024px) {
  * .header-bottom {
    display: flex;
  }
}

* .header-bottom .Header-bottom-middle {
  text-align: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 20px;
  display: flex;
  overflow-x: hidden;
}

* .header-bottom .Header-bottom-middle p {
  white-space: nowrap;
  cursor: pointer;
  width: auto;
  margin: 0 10px;
}

* .header-bottom .Header-bottom-middle p:hover {
  border-bottom: 2px solid #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* .Image-container {
  cursor: pointer;
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
}

* .Image-container .Image {
  display: flex;
  position: relative;
}

@media only screen and (width >= 1024px) {
  * .Image-container .Image {
    width: 100%;
  }
}

* .Image-container .Image .false-img {
  height: inherit;
  width: 300px;
}

* .Image-container .Image img {
  width: 300px;
  transition: transform .3s;
}

* .Image-container .Image .Image-text {
  background-color: #0000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

* .Image-container .Image .Image-text .text-container {
  padding: 10px;
}

* .Image-container .Image .Image-text .text-container .text {
  justify-content: space-between;
  display: flex;
}

* .Image-container .Image .Image-text .text-container .text .text-icon {
  text-wrap: nowrap;
  color: #fff;
  margin-right: 10px;
  overflow-x: hidden;
}

@media only screen and (width >= 768px) {
  * .Image-container .Image .Image-text .text-container .text .text-icon {
    margin-right: 20px;
  }
}

@media only screen and (width >= 1024px) {
  * .Image-container .Image .Image-text .text-container .text .text-icon {
    margin-right: 30px;
  }
}

* .Image-container .Image .Image-text .text-container .text .text-icon a {
  color: inherit;
  opacity: 0;
  font-size: 10px;
  text-decoration: none;
}

@media only screen and (width >= 768px) {
  * .Image-container .Image .Image-text .text-container .text .text-icon a p {
    font-size: 15px;
  }
}

@media only screen and (width >= 1024px) {
  * .Image-container .Image .Image-text .text-container .text .text-icon a p {
    font-size: 20px;
  }
}

* .Image-container .Image .Image-text .text-container .text .icon {
  opacity: 0;
  color: #fff;
  padding: 2px 10px;
}

* .Image-container .Image .Image-text .text-container .text .icon svg {
  size: 30;
}

* .Image-container .Image .Image-text .text-container .text .icon:hover {
  color: #000;
  background-color: #fff;
  border-radius: 2px;
}

* .Image-container:hover .Image img {
  transform: scale(1.2);
}

* .Image-container:hover .Image .Image-text {
  background-color: #00000080;
}

* .Image-container:hover .Image .Image-text .text-container .text .text-icon a, * .Image-container:hover .Image .Image-text .text-container .text .icon {
  opacity: 1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* .div-page-contains .divpage .prev-button, * .div-page-contains .divpage .next-button {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border-style: none;
  border-radius: 10px;
  align-items: center;
  margin: 20px;
  padding: 20px 40px;
  font-size: 20px;
}

.Singleimge-container-div {
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.Singleimge-container-div .cancel {
  color: #f2f2f2;
  position: absolute;
  top: 50px;
  left: 50px;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .cancel {
    top: 50px;
    left: 150px;
  }
}

.Singleimge-container-div .Singleimge-container {
  margin: 100px 50px;
  overflow: hidden;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container {
    margin: 100px 200px;
  }
}

.Singleimge-container-div .Singleimge-container .Singleimage {
  background-color: #f2f2f2;
  border-radius: 10px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container {
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .desc {
  align-items: center;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .desc .src {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .desc .src img {
  border: 2px solid #000;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  padding: 10px;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .desc a {
  color: inherit;
  text-decoration: none;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .desc a span {
  padding-left: 10px;
  font-size: 15px;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .desc a span {
    font-size: 20px;
  }
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .download-div {
  padding: 0 10px;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .download-div .download-button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 10px;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .download-div .download-button {
    padding: 10px 20px;
    font-size: 20px;
  }
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .download-div .size-button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .image .desc-container .download-div .size-button img {
  padding: 0 10px;
}

.Singleimge-container-div .Singleimge-container .Singleimage .img-container {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .img-container img {
  background-color: silver;
  width: 200px;
}

@media only screen and (width >= 768px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .img-container img {
    width: 300px;
  }
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .img-container img {
    width: 300px;
  }
}

.Singleimge-container-div .Singleimge-container .Singleimage .othetr-options {
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .othetr-options-data {
  display: flex;
}

.Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .othetr-options-data p {
  padding: 0 5px;
  font-size: 10px;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .othetr-options-data p {
    padding: 0 10px;
    font-size: 20px;
  }
}

.Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .share-options {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .share-options {
    padding: 10px 20px;
  }
}

.Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .share-options img {
  padding: 0 10px;
}

.Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .share-options button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  padding: 0 5px;
  font-size: 10px;
}

@media only screen and (width >= 1024px) {
  .Singleimge-container-div .Singleimge-container .Singleimage .othetr-options .share-options button {
    padding: 0 10px;
    font-size: 20px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* .gridloader {
  justify-content: center;
  margin-top: 10%;
  display: flex;
}

* .Home-container {
  columns: 2;
  width: auto;
  margin: 20px;
}

@media only screen and (width >= 768px) {
  * .Home-container {
    columns: 3;
    margin: 20px;
  }
}

@media only screen and (width >= 1024px) {
  * .Home-container {
    columns: 4;
    margin: 20px 200px;
  }
}

* .Home-container .Imagecard-container {
  break-inside: avoid;
  width: auto;
  margin-bottom: 10px;
}

* .Home-container .Imagecard-container img {
  border-radius: 10px;
  width: 100%;
}

* .pagechange-container {
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* .search-gridloader {
  justify-content: center;
  margin-top: 10%;
  display: flex;
}

* .search-container {
  columns: 2;
  width: auto;
  margin: 20px;
}

@media only screen and (width >= 768px) {
  * .search-container {
    columns: 3;
    margin: 20px;
  }
}

@media only screen and (width >= 1024px) {
  * .search-container {
    columns: 4;
    margin: 20px 200px;
  }
}

* .search-container .search-Imagecard-container {
  break-inside: avoid;
  width: auto;
  margin-bottom: 10px;
}

* .search-container .search-Imagecard-container img {
  border-radius: 10px;
  width: 100%;
}

* .search-pagechange-container {
  text-align: center;
}

/*# sourceMappingURL=index.eba8ee8e.css.map */
