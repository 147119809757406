*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    .div-page-contains{
        
        .divpage{
            
            .prev-button{
                border-style: none;
                padding: 20px 40px;
                font-size: 20px;
                text-align: center;
                align-items: center;
                margin: 20px;
                background-color: black;
                color: white;
                border-radius: 10px;
                cursor: pointer;
                
            }
            .next-button{
                border-style:none;
                padding: 20px 40px;
                font-size: 20px;
                text-align: center;
                align-items: center;
                margin: 20px;
                background-color: black;
                color: white;
                border-radius: 10px;
                cursor: pointer;
            }

        }

    }

}