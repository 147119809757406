@mixin sm{
    @media only screen and (min-width: 640px)
    {
        @content;
    }
}
@mixin md{
    @media only screen and (min-width: 768px)
    {
        @content;
    }
}

@mixin lg{
    @media only screen and (min-width: 1024px)
    {
        @content;
    }
}

@mixin xl{
    @media only screen and (min-width: 1280px)
    {
        @content;
    }
}

@mixin xxl{
    @media only screen and (min-width: 1536px)
    {
        @content;
    }
}