@import "./../../../../screensize.scss";

.Singleimge-container-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  
  
  .cancel{
    position: absolute;
    color: rgb(242, 242, 242);
    top: 50;
    left: 50;

    @include lg{
      top: 50;
      left: 150;
    }
  }

  .Singleimge-container {
    margin: 100px 50px;
    overflow: hidden;
    @include lg{
      margin: 100px 200px;
    }

    .Singleimage {
      width: 100%;
      background-color: rgb(242, 242, 242);
      height: fit-content;
      border-radius: 10px;

      .image{

        .desc-container{
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          align-items: center;
        .desc{
          display: flex;
          align-items: center;
          .src {
            display: flex;
            justify-content: center; // Center horizontally
            align-items: center; // Center vertically
            text-align: center;
          
            img {
              width: 50px;
              height: 50px;
              border: 2px solid black;
              border-radius: 30px;
              padding: 10px;
            }
          }
          
          a{
            text-decoration: none;
            color:inherit;
          span{
            padding-left: 10px;
            font-size: 15px;
            @include lg{
              font-size: 20px;
            }
          }
        }
        }

          .download-div{
            display: flex;
            padding: 0 10px;
            .download-button{
              background-color: black;
              color: white;
              padding:5px 10px;
              border: none;
              border-radius: 5px;
              font-size: 10px;
              cursor: pointer;

              @include lg{
                font-size: 20px;
                padding:10px 20px;
              }
            }
            .size-button{
              display: flex;
              justify-content: center;
              background-color: black;
              border-radius: 5px;
              color: white;
              margin-left: 10px;
              cursor: pointer;
              align-items: center;

              img{
                padding:0 10px;
              }
            }

          }

        }
        }
        .img-container{
          display: flex;
          justify-content: center;
          margin-top: 20px;
          

          img{
            width:200px;
            background-color: rgb(192, 192, 192);
           
            @include md {
              width:300px;

            }
            @include lg{
              width:300px;
            }
          }
        }

        .othetr-options
        {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px;
          padding: 20px;
          .othetr-options-data{
            display: flex;
            p{
              padding: 0 5px;
              font-size: 10px;

              @include lg{
                font-size: 20px;
                padding: 0 10px;
              }
            }
          }
          .share-options{
            padding-right: 10px;
            background-color: black;
            border-radius: 5px;
            color: white;
            align-items: center;
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            @include lg{
              padding: 10px 20px;
            }
            img{
             padding: 0 10px;
            }
            button{
              color:white;
              border:none;
              background-color: black;
              padding: 0 5px;
              font-size: 10px;
              cursor: pointer;

              @include lg{
                padding: 0 10px;
                font-size: 20px;
              }
            }
          }
        }
      }

    }
  }

